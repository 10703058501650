import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { DownloadMarketingMaterials } from '../sections/Tell/Download';
import { ParentsUpdate } from '../sections/Tell/ParentsUpdate';
import { TellHero } from '../sections/Tell/TellHero';
import {SEO} from "../components/Seo/Seo";

const TellParents = () => {
	return (
		<MainLayout>
			<ScPage>
				<TellHero isCustomers={false} />
				<ParentsUpdate />
				<DownloadMarketingMaterials />
			</ScPage>
		</MainLayout>
	);
};

export default TellParents;

export const Head = () => (
	<SEO
		title="Tell Parents About Gogeta | Extra Funding for Nurseries"
		description="Let parents know about Gogeta Nursery to help your nursery receive 12.5% extra funding per child. Encourage parents to get their employers signed up today."
	/>
)

import React from 'react';
import { useTheme } from 'styled-components';

import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import customerImage from '../../assets/images/bike-logo.png';
import parentImage from '../../assets/images/nursery-logo.png';
import { ScContainer } from '../../components/container/styled';

import {
	ScTellHeroContainer,
	ScTellHeroContent,
	ScTellHeroContentLeft,
	ScTellHeroContentRight,
	ScTellHeroWrapper,
} from './styled';

export const TellHero = ({ isCustomers }: { isCustomers: boolean }) => {
	const theme = useTheme();
	return (
		<ScTellHeroContainer>
			<Top />
			<ScTellHeroWrapper>
				<ScContainer>
					<ScTellHeroContent>
						<ScTellHeroContentLeft>
							<h1>
								{isCustomers
									? 'Tell your customers about Gogeta Bike'
									: 'Tell parents about Gogeta Nursery'}
							</h1>
							<div>
								{isCustomers ? (
									<p>
										By letting your customers know you're a Gogeta Bike retailer partner, you can
										save them money and benefit from <b>Gogeta's low commission of just 3%.</b>
									</p>
								) : (
									<>
										<p>
											For every parent at your nursery that pays their fees with Gogeta Nursery,{' '}
											<b>
												you will receive additional funding of 12.5% of their fees for your nursery.
											</b>
										</p>
										<p>
											Many parents don't even know this scheme exists, so it's definitely worth
											letting them know so they can get their employer signed up.
										</p>
									</>
								)}
							</div>
						</ScTellHeroContentLeft>
						<ScTellHeroContentRight
							background={isCustomers ? theme.colors.marmelade : theme.colors.blueberry}
						>
							<img src={isCustomers ? customerImage : parentImage} alt="hero-image" />
						</ScTellHeroContentRight>
					</ScTellHeroContent>
				</ScContainer>
			</ScTellHeroWrapper>
		</ScTellHeroContainer>
	);
};

import styled from 'styled-components';

export const ScTellHeroContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.strongWhite};
	padding-top: 65px;
	svg {
		width: 130vw;
		height: auto;
		margin-left: -15vw;
	}
	@media (min-width: 768px) {
		padding-top: 80px;
	}
	@media (min-width: 1220px) {
		padding-top: 120px;
	}
`;

export const ScTellHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.white};
`;

export const ScTellHeroContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	padding-bottom: 40px;
	box-sizing: border-box;
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 60px;
		margin-top: -70px;
	}
`;

export const ScTellHeroContentLeft = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	box-sizing: border-box;
	> div {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	b {
		font-family: 'GeomanistMedium';
	}
	h1 {
		font: ${({ theme }) => theme.fonts.heading.medium};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.heading.large};
		}
		color: ${({ theme }) => theme.colors.darkText};
	}
	p {
		color: ${({ theme }) => theme.colors.darkText};
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.paragraph.medium};
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}
	}
	@media (min-width: 1220px) {
		padding: 0 60px;
		h3,
		p {
			max-width: 665px;
		}
	}
`;

interface IScTellHeroContentRight {
	background?: string;
}

export const ScTellHeroContentRight = styled.div<IScTellHeroContentRight>`
	height: 100%;
	display: flex;
	padding: 20px;
	justify-content: center;
	align-items: center;
	width: 100%;
	background: ${({ theme, background }) => background || theme.colors.blueberry};
	border-radius: 10px;
	box-sizing: border-box;
	img {
		height: auto;
		max-width: 360px;
		width: 100%;
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 40px;
	}
`;

export const ScUpdateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	background: ${({ theme }) => theme.colors.wheat};
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 40px;
	@media (min-width: 1220px) {
		padding: 40px 60px;
		border-radius: 30px;
	}
	b {
		font-family: 'GeomanistMedium';
	}
	h3 {
		font: ${({ theme }) => theme.fonts.heading.tiny};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.tell.updateTitle};
		}
		color: ${({ theme }) => theme.colors.darkText};
	}
	p {
		color: ${({ theme }) => theme.colors.darkText};
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.paragraph.medium};
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}
	}
	li {
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
	}
	ol {
		padding-left: 25px;
	}
`;

export const ScTellDownloadWrapper = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column-reverse;
	gap: 20px;
	margin-bottom: 40px;
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
	}
`;

export const ScTellPeachDownloadWrapper = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 20px;
	@media (min-width: 768px) {
		padding: 40px;
	}
`;

export const ScTellPeachDownloadRowWrapper = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 20px;
	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const ScTellPeachDownloadItemWrapper = styled.div`
	background: ${({ theme }) => theme.colors.peach};
	padding: 20px 30px;
	border-radius: 10px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: center;
	text-align: center;
	h3 {
		font: ${({ theme }) => theme.fonts.card.labelLarge};
		color: ${({ theme }) => theme.colors.darkText};
	}
	@media (min-width: 768px) {
		padding: 40px 60px;
		border-radius: 30px;
	}
`;

export const ScTellDownloadTextWrapper = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 24px;
	b {
		font-family: 'GeomanistMedium';
		a {
			text-decoration: none !important;
		}
	}
	h3 {
		font: ${({ theme }) => theme.fonts.heading.tiny};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.tell.updateTitle};
		}
		color: ${({ theme }) => theme.colors.darkText};
	}
	p {
		color: ${({ theme }) => theme.colors.darkText};
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.paragraph.medium};
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}
	}
	span {
		color: ${({ theme }) => theme.colors.darkText};
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		a {
			color: ${({ theme }) => theme.colors.darkText};
			cursor: pointer;
			text-decoration: underline;
		}
	}
	@media (min-width: 768px) {
		padding: 0 40px;
	}
	@media (min-width: 1220px) {
		padding: 0 60px;
	}
`;

export const ScTellUpdateCustomerstWrapper = styled.div`
	display: flex;
	flex-direction: column;
	> div {
		margin-bottom: 20px;
	}
	.code {
		color: ${({ theme }) => theme.colors.darkText};
		background: ${({ theme }) => theme.colors.white};
		padding: 12px 16px;
		box-sizing: border-box;
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		border-radius: 8px;
	}
`;

export const ScAboutUsHeroTextColumns = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
	div {
		display: flex;
		flex-direction: column;
		gap: 10px;
		p {
			font: ${({ theme }) => theme.fonts.tell.smallText};
		}
	}
	a {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.darkText};
	}
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;
	}
`;
